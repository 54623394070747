<template>
  <v-dialog v-model="editWorkGroupForm.dialog" max-width="600px" scrollable>
    <v-card>
      <v-card-title>
        <span class="headline">Edit Work Group</span>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5">
        <v-form @submit.prevent="editWorkGroup" method="post" id="edit-group">
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name *"
                type="text"
                v-model="editWorkGroupForm.name"
                outlined
                dense
                :error="editWorkGroupForm.errors.hasOwnProperty('name')"
                :error-messages="editWorkGroupForm.errors['name']"
                :hide-details="!editWorkGroupForm.errors.hasOwnProperty('name')"
              ></v-text-field>
            </v-col>

            <v-col cols="4">
              <v-switch
                v-model="editWorkGroupForm.is_internal"
                :error="editWorkGroupForm.errors.hasOwnProperty('is_internal')"
                :error-messages="editWorkGroupForm.errors['is_internal']"
                :hide-details="
                  !editWorkGroupForm.errors.hasOwnProperty('is_internal')
                "
                class="mt-1"
                label="Is Internal?"
              ></v-switch>
            </v-col>
            <v-col cols="8">
              <v-select
                v-if="editWorkGroupForm.is_internal == false"
                label="Customer"
                :items="filteredCustomers"
                v-model="editWorkGroupForm.customer_id"
                item-text="name"
                item-value="id"
                :return-object="false"
                outlined
                dense
                :hide-details="
                  !editWorkGroupForm.errors.hasOwnProperty('customer_id')
                "
                :error="editWorkGroupForm.errors.hasOwnProperty('customer_id')"
                :error-messages="editWorkGroupForm.errors['customer_id']"
              >
                <template v-slot:prepend-item>
                  <div class="pa-3">
                    <v-text-field
                      label="Search"
                      type="text"
                      v-model="searchTermSelect"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-select
                label="Status"
                v-model="editWorkGroupForm.status"
                :items="statuses"
                outlined
                background-color="white"
                :error="editWorkGroupForm.errors.hasOwnProperty('status')"
                :error-messages="editWorkGroupForm.errors['status']"
                :hide-details="
                  !editWorkGroupForm.errors.hasOwnProperty('status')
                "
              >
              </v-select>
            </v-col>

            <v-col cols="12">
              <v-textarea
                label="Description"
                v-model="editWorkGroupForm.description"
                outlined
                background-color="white"
                :hide-details="
                  !editWorkGroupForm.errors.hasOwnProperty('description')
                "
                :error="editWorkGroupForm.errors.hasOwnProperty('description')"
                :error-messages="editWorkGroupForm.errors['description']"
              ></v-textarea>
            </v-col>

            <v-col cols="12">
              <v-select
                label="Parent Workgroup"
                v-model="editWorkGroupForm.parent_workgroup"
                :items="filteredGroups"
                item-text="name"
                item-value="id"
                outlined
                background-color="white"
                :error="
                  editWorkGroupForm.errors.hasOwnProperty('parent_workgroup')
                "
                :error-messages="editWorkGroupForm.errors['parent_workgroup']"
                :hide-details="
                  !editWorkGroupForm.errors.hasOwnProperty('parent_workgroup')
                "
              >
                <template v-slot:prepend-item>
                  <div class="pa-3">
                    <v-text-field
                      label="Search"
                      type="text"
                      v-model="searchTermWorkGroupSelect"
                      outlined
                      dense
                      hide-details
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                label="Type* "
                v-model="editWorkGroupForm.type"
                :items="types"
                outlined
                background-color="white"
                :error="editWorkGroupForm.errors.hasOwnProperty('type')"
                :error-messages="editWorkGroupForm.errors['type']"
                :hide-details="!editWorkGroupForm.errors.hasOwnProperty('type')"
              ></v-select>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="editWorkGroupForm.dialog = false"
          >Close</v-btn
        >
        <v-btn
          color="accent"
          text
          type="submit"
          form="edit-group"
          :loading="editWorkGroupForm.loading"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      searchTerm: "",
      searchType: null,
      searchTermSelect: "",
      searchTermWorkGroupSelect: "",
      editWorkGroupForm: {
        dialog: false,
        name: null,
        type: null,
        customer_id: null,
        is_internal: false,
        parent_workgroup: null,
        status: null,
        loading: false,
        description: null,
        errors: {},
      },
      types: ["Contract", "Project", "Ad-hoc", "Internal"],
      statuses: ["Open", "Closed"],
    };
  },

  computed: {
    customers() {
      return this.$store.state.customers["customers"];
    },
    teamMembers() {
      return this.$store.state.team["teamMembers"];
    },

    groups() {
      let groups = this.$store.getters["workload/parentWorkGroup"];

      if (this.searchTerm !== "") {
        groups = groups.filter((c) => {
          const name = c.name.toLowerCase();

          const customer =
            c.customer !== null ? c.customer.name.toLowerCase() : "";
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            customer.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      if (this.searchType !== null) {
        groups = groups.filter((c) => {
          const type = c.type;
          return type.includes(this.searchType);
        });
      }

      return groups;
    },

    filteredGroups() {
      let groups = this.$store.state.workload["groups"];
      if (
        this.searchTermWorkGroupSelect !== "" ||
        this.searchTermWorkGroupSelect !== null
      ) {
        groups = groups.filter((g) => {
          const name = g.name.toLowerCase();
          return name.includes(this.searchTermWorkGroupSelect.toLowerCase());
        });
      }

      return groups;
    },

    filteredCustomers() {
      let customers = this.$store.state.customers["customers"];
      if (this.searchTermSelect !== "" || this.searchTermSelect !== null) {
        customers = customers.filter((c) => {
          const name = c.name.toLowerCase();
          const shortcode = c.shortcode ? c.shortcode.toLowerCase() : "";
          return (
            name.includes(this.searchTermSelect.toLowerCase()) ||
            shortcode.includes(this.searchTermSelect.toLowerCase())
          );
        });
      }

      return customers;
    },
  },

  methods: {
    editWorkGroup: function () {
      const appId = this.$route.params.id;
      const name = this.editWorkGroupForm.name;
      const groupId = this.editWorkGroupForm.groupId;
      const customer_id = this.editWorkGroupForm.customer_id;
      const type = this.editWorkGroupForm.type;
      const parent_workgroup = this.editWorkGroupForm.parent_workgroup;
      const description = this.editWorkGroupForm.description;
      const is_internal = this.editWorkGroupForm.is_internal;
      const status = this.editWorkGroupForm.status;

      this.editWorkGroupForm.loading = true;

      this.$store
        .dispatch("workload/editGroup", {
          appId,
          groupId,
          name,
          customer_id,
          type,
          parent_workgroup,
          description,
          is_internal,
          status,
        })
        .then(() => {
          this.editWorkGroupForm = {
            dialog: false,
            name: null,
            type: null,
            parent_workgroup: null,
            customer_id: null,
            description: null,
            is_internal: false,
            status: null,
            loading: false,
            errors: {},
          };
        })
        .catch((err) => {
          this.editWorkGroupForm.errors = err.response.data.errors;
          this.editWorkGroupForm.loading = false;
        });
    },

    openEditWorkGroup: function (group) {
      this.editWorkGroupForm = {
        dialog: true,
        groupId: group.id,
        name: group.name,
        type: group.type,
        description: group.description,
        parent_workgroup: group.parent_workgroup,
        status: group.status,
        is_internal: Boolean(Number(group.is_internal)),
        customer_id: group.customer_id,
        loading: false,
        errors: {},
      };
    },
  },
};
</script>
