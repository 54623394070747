<template>
  <v-dialog v-model="dialog" max-width="600px" @click:outside="resetForm()">
    <v-card>
      <v-form @submit.prevent="saveForm" method="post">
        <v-card-title>
          <span class="headline">{{ isEditing ? "Edit" : "New" }} Block</span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                label="Name *"
                type="text"
                v-model="fields.name"
                outlined
                :hide-details="!errors.hasOwnProperty('name')"
                :error="errors.hasOwnProperty('name')"
                :error-messages="errors['name']"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                label="Assigned to"
                :items="filteredTeam"
                v-model="fields.assigned"
                item-text="full_name_from_team"
                item-value="id"
                :return-object="false"
                multiple
                outlined
                :hide-details="!errors.hasOwnProperty('assigned')"
                :error="errors.hasOwnProperty('assigned')"
                :error-messages="errors['assigned']"
              >
                <template v-slot:prepend-item>
                  <div class="pa-3">
                    <v-text-field
                      label="Search"
                      type="text"
                      v-model="searchTermTeam"
                      outlined
                      hide-details
                    ></v-text-field>
                  </div>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-text-field
                label="To Do Date"
                v-model="fields.to_do"
                type="date"
                outlined
                background-color="white"
                :hide-details="!errors.hasOwnProperty('to_do')"
                :error="errors.hasOwnProperty('to_do')"
                :error-messages="errors['to_do']"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
          <v-btn color="accent" text type="submit" :loading="loading"
            >Save</v-btn
          >
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    groups: {
      type: Array,
      required: true,
    },
    customers: {
      type: Array,
      required: true,
    },
    teamMembers: {
      type: Array,
      required: true,
    },
    customerId: {
      type: Number,
      required: false,
    },
  },

  data() {
    return {
      dialog: false,
      completion_date_picker: null,
      loading: false,
      errors: {},
      fields: {
        name: null,
        description: null,
        customer_id: null,
        status: "Open",
        value: null,
        rate: null,
        locked: false,
        expected_completion_date: null,
        to_do: null,
        is_internal: false,
        team_lead: null,
        type: null,
        groups: null,
        assigned: null,
      },
      searchTerm: "",
      searchTermGroup: "",
      searchTermTeam: "",
      isEditing: false,
      fromWorkGroup: false,
      workChunk: {},
      statuses: ["Open", "Closed", "Deleted", "Completed"],
      types: ["Project", "Ad-hoc", "Unknown", "Contract", "Diary"],
    };
  },

  created() {
    if (this.customerId) {
      this.fields.customer_id = this.customerId;
    }
  },

  computed: {
    currentTeamMember() {
      return this.$store.state.workload["user"];
    },

    dateFormatted() {
      return this.formatDate(this.fields.expected_completion_date);
    },

    filteredCustomers() {
      let customers = this.customers;
      if (this.searchTerm !== "" || this.searchTerm !== null) {
        customers = customers.filter((c) => {
          const name = c.name.toLowerCase();
          const shortcode = c.shortcode ? c.shortcode.toLowerCase() : "";
          return (
            name.includes(this.searchTerm.toLowerCase()) ||
            shortcode.includes(this.searchTerm.toLowerCase())
          );
        });
      }

      return customers;
    },

    filteredGroup() {
      let groups = this.groups;
      if (this.searchTermGroup !== "" || this.searchTermGroup !== null) {
        groups = groups.filter((c) => {
          const name = c.name.toLowerCase();
          return name.includes(this.searchTermGroup.toLowerCase());
        });
      }

      return groups;
    },

    filteredTeam() {
      let teamMembers = this.$store.state.team["teamMembers"];

      if (
        (this.searchTermTeam !== "" || this.searchTermTeam !== null) &&
        typeof this.searchTermTeam === "string"
      ) {
        teamMembers = teamMembers.filter((m) => {
          const name = m.full_name.toLowerCase();
          const email = m.email.toLowerCase();
          return (
            name.includes(this.searchTermTeam.toLowerCase()) ||
            email.includes(this.searchTermTeam.toLowerCase())
          );
        });
      }

      return teamMembers;
    },
  },

  methods: {
    send: function () {
      const appId = this.$route.params.id;
      const customerId = this.fields.is_internal
        ? null
        : this.fields.customer_id;

      this.loading = true;

      this.$store
        .dispatch("workload/loadCustomerGroups", {
          appId,
          customerId,
        })
        .then((res) => {
          this.loading = false;
          this.groups = res.data.groups;
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    changeGroup: function () {
      const customerId = this.fields.customer_id;
      let groups = this.groups;
      groups = groups.filter((c) => {
        return c.customer_id == customerId;
      });

      return groups;
    },

    openForm: function (workGroup) {
      this.fields.team_lead = this.currentTeamMember.id;
      this.fields.groups = workGroup.id;
      this.fields.customer_id = workGroup.customer_id;
      this.fields.is_internal = workGroup.is_internal;
      this.fields.type = workGroup.type;

      this.workGroup = workGroup;

      this.dialog = true;
    },

    saveForm: function () {
      const appId = this.$route.params.id;

      this.loading = true;
      this.errors = {};

      let payload = {
        appId,
        fields: this.fields,
        isEditing: this.isEditing,
        quickTask: this.quickTask,
      };

      if (this.isEditing) {
        payload.id = this.workChunk.id;
      }

      this.$store
        .dispatch("workload/saveWorkChunk", {
          ...payload,
        })
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.completion_date_picker = null;
      this.loading = false;
      this.errors = {};
      this.fields = {
        name: null,
        description: null,
        customer_id: this.customerId ?? null,
        status: "Open",
        value: null,
        rate: null,
        locked: false,
        expected_completion_date: null,
        to_do: null,
        is_internal: false,
        team_lead: this.currentTeamMember.id,
        type: null,
        groups: null,
        assigned: null,
      };
      this.isEditing = false;

      this.workChunk = {};
    },
  },
};
</script>
